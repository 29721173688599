import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Hr, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Benvenuti in AutoPrime
			</title>
			<meta name={"description"} content={"Cura dell'auto eccezionale, ogni volta"} />
			<meta property={"og:title"} content={"Benvenuti in AutoPrime"} />
			<meta property={"og:description"} content={"Cura dell'auto eccezionale, ogni volta"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/665dc0771d712a0023346b1a/images/2-4.jpg?v=2024-06-10T08:58:48.096Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/665dc0771d712a0023346b1a/images/2-4.jpg?v=2024-06-10T08:58:48.096Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/665dc0771d712a0023346b1a/images/2-4.jpg?v=2024-06-10T08:58:48.096Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/665dc0771d712a0023346b1a/images/2-4.jpg?v=2024-06-10T08:58:48.096Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/665dc0771d712a0023346b1a/images/2-4.jpg?v=2024-06-10T08:58:48.096Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/665dc0771d712a0023346b1a/images/2-4.jpg?v=2024-06-10T08:58:48.096Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/665dc0771d712a0023346b1a/images/2-4.jpg?v=2024-06-10T08:58:48.096Z"} />
		</Helmet>
		<Components.Header />
		<Components.Marque />
		<Section padding="100px 0 100px 0" lg-padding="80px 0 80px 0" quarkly-title="Content-14">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					display="flex"
					flex-direction="row"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/665dc0771d712a0023346b1a/images/2-1.jpg?v=2024-06-10T08:58:48.100Z"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 5px 0px 0px"
						md-height="500px"
						max-height="500px"
						srcSet="https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/2-1.jpg?v=2024-06-10T08%3A58%3A48.100Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/2-1.jpg?v=2024-06-10T08%3A58%3A48.100Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/2-1.jpg?v=2024-06-10T08%3A58%3A48.100Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/2-1.jpg?v=2024-06-10T08%3A58%3A48.100Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/2-1.jpg?v=2024-06-10T08%3A58%3A48.100Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/2-1.jpg?v=2024-06-10T08%3A58%3A48.100Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/2-1.jpg?v=2024-06-10T08%3A58%3A48.100Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					width="50%"
					padding="0px 80px 0px 0"
					justify-content="center"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
					order="-1"
					align-items="center"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="--headline2"
						sm-font="normal 700 42px/1.2 --fontFamily-sans"
						color="--darkL1"
						lg-margin="0px 0px 35px 0px"
						text-align="center"
					>
						I nostri servizi spiegati
					</Text>
					<Hr
						min-height="10px"
						margin="0px 0px 20px 0px"
						width="50px"
						display="flex"
						justify-content="center"
						border-width="2px 0 0 0"
						border-color="--color-darkL1"
					/>
					<Text margin="0px 0px 25px 0px" font="--base" color="--darkL1" text-align="center">
						Noi di AutoPrime offriamo una gamma completa di servizi progettati per coprire tutti gli aspetti della manutenzione e riparazione del veicolo. Dalle messe a punto di base alla diagnostica avanzata, il nostro team utilizza la tecnologia più recente per fornire risultati impeccabili. La nostra struttura è attrezzata per gestire tutte le esigenze del vostro veicolo con precisione e competenza, garantendo che ogni cliente lasci pienamente soddisfatto.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" max-width="960px" />
			<Box
				quarkly-title="Card"
				justify-content="space-between"
				sm-flex-direction="column"
				position="relative"
				lg-margin="0px 0px 64px 0px"
				sm-align-items="center"
				display="flex"
				align-items="center"
				margin="0px 0px 96px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/665dc0771d712a0023346b1a/images/2-2.jpg?v=2024-06-10T08:58:48.105Z"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					srcSet="https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/2-2.jpg?v=2024-06-10T08%3A58%3A48.105Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/2-2.jpg?v=2024-06-10T08%3A58%3A48.105Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/2-2.jpg?v=2024-06-10T08%3A58%3A48.105Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/2-2.jpg?v=2024-06-10T08%3A58%3A48.105Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/2-2.jpg?v=2024-06-10T08%3A58%3A48.105Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/2-2.jpg?v=2024-06-10T08%3A58%3A48.105Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/2-2.jpg?v=2024-06-10T08%3A58%3A48.105Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					sm-margin="0px 0px 0px 0px"
					sm-text-align="left"
					margin="0px 0px 0px 8%"
					max-width="450px"
					md-margin="0px 0px 0px 24px"
					font="--base"
				>
					<Strong>
						Meccanica Esperta
						<br />
					</Strong>
					<br />
					I nostri meccanici non sono solo tecnici; sono appassionati di auto con la passione per l'eccellenza automobilistica. Ogni membro del nostro team ha una formazione approfondita e un occhio attento ai dettagli, garantendo che il tuo veicolo riceva la migliore cura possibile.
				</Text>
			</Box>
			<Box
				align-items="center"
				sm-flex-direction="column-reverse"
				sm-align-items="center"
				quarkly-title="Card"
				display="flex"
			>
				<Box sm-margin="0px 0px 0px 0px" max-width="450px" margin="0px 8% 0px 0px" md-margin="0px 24px 0px 0px">
					<Text font="--base" sm-text-align="left">
						Manutenzione ordinaria: per mantenere il tuo veicolo perfettamente funzionante.{"\n"}
						<br />
						Cambi dell'olio: Essenziale per la salute e la longevità del motore.{"\n"}
						<br />
						Manutenzione del sistema frenante: Garantire la tua sicurezza sulla strada.{"\n"}
						<br />
						{"\n"}Diagnostica avanzata: Individuazione dei problemi con precisione.{"\n"}
						<br />
						Diagnostica del motore: Risoluzione efficiente di problemi complessi.{"\n"}
						<br />
						Controlli dell'impianto elettrico: Prevenzione di guasti futuri.{" "}
					</Text>
				</Box>
				<Image
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					align-self="flex-end"
					src="https://uploads.quarkly.io/665dc0771d712a0023346b1a/images/2-3.jpg?v=2024-06-10T08:58:48.105Z"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					max-height="400px"
					srcSet="https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/2-3.jpg?v=2024-06-10T08%3A58%3A48.105Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/2-3.jpg?v=2024-06-10T08%3A58%3A48.105Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/2-3.jpg?v=2024-06-10T08%3A58%3A48.105Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/2-3.jpg?v=2024-06-10T08%3A58%3A48.105Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/2-3.jpg?v=2024-06-10T08%3A58%3A48.105Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/2-3.jpg?v=2024-06-10T08%3A58%3A48.105Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/2-3.jpg?v=2024-06-10T08%3A58%3A48.105Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" max-width="960px" />
			<Box
				quarkly-title="Card"
				justify-content="space-between"
				sm-flex-direction="column"
				position="relative"
				lg-margin="0px 0px 64px 0px"
				sm-align-items="center"
				display="flex"
				align-items="center"
				margin="0px 0px 96px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/665dc0771d712a0023346b1a/images/1-4.jpg?v=2024-06-10T08:58:48.104Z"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					max-height="400px"
					srcSet="https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/1-4.jpg?v=2024-06-10T08%3A58%3A48.104Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/1-4.jpg?v=2024-06-10T08%3A58%3A48.104Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/1-4.jpg?v=2024-06-10T08%3A58%3A48.104Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/1-4.jpg?v=2024-06-10T08%3A58%3A48.104Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/1-4.jpg?v=2024-06-10T08%3A58%3A48.104Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/1-4.jpg?v=2024-06-10T08%3A58%3A48.104Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/1-4.jpg?v=2024-06-10T08%3A58%3A48.104Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					sm-margin="0px 0px 0px 0px"
					sm-text-align="left"
					margin="0px 0px 0px 8%"
					max-width="450px"
					md-margin="0px 0px 0px 24px"
					font="--base"
				>
					Servizi di riparazione: Ripristino delle condizioni ottimali del tuo veicolo.
Riparazioni della trasmissione: Cura complessa del cambio del tuo veicolo.{"\n"}
					<br />
					Lavoro sulle sospensioni: miglioramento del comfort di guida e della manovrabilità.{"\n"}
					<br />
					{"\n"}Miglioramenti delle prestazioni: Potenziamento delle capacità del tuo veicolo.{"\n"}
					<br />
					Sistemi di scarico: Miglioramento dell'efficienza e del suono.{"\n"}
					<br />
					Tuning e rimappature: Ottimizzazione delle prestazioni del motore.
				</Text>
			</Box>
			<Box
				align-items="center"
				sm-flex-direction="column-reverse"
				sm-align-items="center"
				quarkly-title="Card"
				display="flex"
			>
				<Box sm-margin="0px 0px 0px 0px" max-width="450px" margin="0px 8% 0px 0px" md-margin="0px 24px 0px 0px">
					<Text font="--base" sm-text-align="left">
						I servizi elencati sono solo l'inizio di ciò che offriamo ad AutoPrime. Per comprendere veramente l'intera gamma delle nostre offerte, vi invitiamo a contattarci o a visitare la nostra struttura. Scopri l’ampia gamma di servizi pensati per soddisfare ogni esigenza del tuo veicolo.
						<br />
						<br />
						<br />
						Prenota il tuo servizio oggi e lascia che ti mostriamo perché AutoPrime è leader nella cura dell'auto. Il nostro impegno per la qualità e la soddisfazione del cliente garantisce ogni volta un'esperienza di servizio superiore. Noi di AutoPrime non ci limitiamo a riparare le auto, le perfezioniamo.
					</Text>
				</Box>
				<Image
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					align-self="flex-end"
					src="https://uploads.quarkly.io/665dc0771d712a0023346b1a/images/1-1%20%281%29.jpg?v=2024-06-10T08:58:48.105Z"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					srcSet="https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/1-1%20%281%29.jpg?v=2024-06-10T08%3A58%3A48.105Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/1-1%20%281%29.jpg?v=2024-06-10T08%3A58%3A48.105Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/1-1%20%281%29.jpg?v=2024-06-10T08%3A58%3A48.105Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/1-1%20%281%29.jpg?v=2024-06-10T08%3A58%3A48.105Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/1-1%20%281%29.jpg?v=2024-06-10T08%3A58%3A48.105Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/1-1%20%281%29.jpg?v=2024-06-10T08%3A58%3A48.105Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665dc0771d712a0023346b1a/images/1-1%20%281%29.jpg?v=2024-06-10T08%3A58%3A48.105Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});